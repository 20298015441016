import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { PaddingContainer } from "../elements/shared/containers"
import "../styles/post.css"
import BlogPost from "../components/BlogPost"
import SEO from "../components/seo"

const Article = ({ data }) => {
  const description = data.contentfulArticle.body.childMdx.excerpt
  const meta = data.contentfulArticle.tags.map(item => item.title)
  const author = data.contentfulArticle.author.name
  const url = `/article/${data.contentfulArticle.slug}`
  const image = data.contentfulArticle.image
  const title = data.contentfulArticle.title

  return (
    <Layout hideFooter={true}>
      <SEO
        description={description}
        meta={meta}
        title={title}
        author={author}
        url={url}
        image={image}
      />
      <PaddingContainer>
        <BlogPost data={data} />
      </PaddingContainer>
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      image
      createdDate(formatString: "dddd, DD MMMM YYYY")
      videoUrl
      updatedDate(fromNow: true)
      isNewLayout
      previews {
        previews
      }
      slug
      author {
        email
        name
      }
      tags {
        title
      }
      allChapters
      code {
        childMdx {
          body
        }
      }
      explanation {
        childMdx {
          body
        }
      }
      downloads {
        name
        url
      }

      resources {
        name
        url
      }
      body {
        childMdx {
          tableOfContents
          body
          excerpt(pruneLength: 300)
        }
      }
    }
  }
`

export default Article
