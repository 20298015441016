import React,{ useState } from "react"
import {

  H4,
} from "../elements/shared/texts"
import "../styles/post.css"
import CoursePostHeader from "./CoursePostHeader"
import CourseVideoContainer from "./CourseVideoContainer"
import CourseBody from "./CourseBody"
import FloatingComponent from "./FloatingComponent"
import { LI, OL } from "../elements/shared/lists"
import { UseGlobalStateContext } from "../context/globalContext"
import { getTheme } from "../themes/theme"
import { formatStringToId } from "../helpers/utils.js"
import { useDynamicDimensions } from "../elements/hooks/use-dimensions";
import Lesson from "./post/Lesson";

const BlogPost = ({ data }) => {
  const { currentTheme } = UseGlobalStateContext()
  const theme = getTheme(currentTheme)
  const [oldLayout, setOldLayout] = useState(false)

  const dimensions = useDynamicDimensions()


  if (!oldLayout && data.contentfulArticle.isNewLayout && dimensions.width > 640) {
    return (
      <div>
        <CoursePostHeader
          data={data.contentfulArticle}
          selectedLesson={data.contentfulArticle}
          oldLayout={oldLayout}
          setOldLayout={setOldLayout}
          isNewLayout={data.contentfulArticle.isNewLayout}
        />
        <Lesson
          lesson={data.contentfulArticle}
          handleSelectedLesson={() => {}}
        />
        <FloatingComponent
          downloads={data.contentfulArticle.downloads}
          resources={data.contentfulArticle.resources}
        >
          <H4 >Table of contents</H4>
          <OL>
            {data.contentfulArticle.body.childMdx.tableOfContents.items &&
            data.contentfulArticle.body.childMdx.tableOfContents.items.map(item => (
              <LI
                key={item.title}
                className="py-2"
                whileHover={{
                  y: -5,
                  borderRadius: "10px",
                  color: theme.colors.accent,
                  backgroundColor: theme.colors.border,
                }}
              >
                  <span className="pl-2">
                    <a href={`#${formatStringToId(item.title)}`} >{item.title} </a>
                  </span>
              </LI>
            ))}
          </OL>
        </FloatingComponent>
      </div>
    )
  }

  return (

    <div className="container mx-auto px-0 md:py-20 py-8">
      <CoursePostHeader data={data.contentfulArticle}
                        selectedLesson={data.contentfulArticle}
                        oldLayout={oldLayout}
                        setOldLayout={setOldLayout}
                        isNewLayout={data.contentfulArticle.isNewLayout}
      />
      <div className=" sm:px-0 max-h-64 mb-8">
        <CourseVideoContainer  article={data.contentfulArticle}   />
      </div>
      <FloatingComponent downloads={data.contentfulArticle.downloads} resources={data.contentfulArticle.resources} >
        <H4 >Table of contents</H4>
        <OL>
          {data.contentfulArticle.body.childMdx.tableOfContents.items &&
          data.contentfulArticle.body.childMdx.tableOfContents.items.map(item => (
            <LI
            key={item.title}
              className="py-2"
              whileHover={{
                y: -5,
                borderRadius: "10px",
                color: theme.colors.accent,
                backgroundColor: theme.colors.border,
              }}
            >
                <span className="pl-2">
                  <a href={`#${formatStringToId(item.title)}`} >{item.title} </a>
                </span>
            </LI>
          ))}
        </OL>
      </FloatingComponent>
      <CourseBody  body={data.contentfulArticle.body.childMdx.body} />
    </div>

  )
}

export default BlogPost
