import React from "react"
import Video from "./Video"
import { VideoContainer } from "../elements/shared/post"
import styled from "styled-components"
import { PricingPlans } from "../utils/constants"

const Img = styled('img')`
    border-color: ${props => props.theme.colors.border};
    border-width: ${props => props.theme.measurements.borderWidth};
    border-radius: 3px;
    -webkit-border-radius: 3px; 
    -moz-border-radius: 3px; 
`

const CourseVideoContainer = (props) => {


    if (props.videoUrl ) {
      return  <Video videoUrl={props.videoUrl } />
    } else if (props.imageUrl) {
      return <Img src={props.imageUrl} /> 
    } else {
      return null
    }
}

export default CourseVideoContainer